* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  min-height: 100vh;
}

body {
  background-color: #0f1b4d;
  min-height: 100vh;
}

.App {
  width: 100vw;
  max-width: 1080px;
  min-height: 100vh;
  margin: 0 auto;
}

.btn-globe {
  width: 46px;
  height: 46px;
  display: block;
  border: none;
  background: transparent;
  margin-right: 15px;
}

.btn-globe img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 46px;
}

.btn-city
{
    width: 46px;
    height: 46px;
    display: block;
    border: none;
    background: transparent;
    margin-right: 15px;
}

.btn-city img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 46px;
}

@media only screen and (max-width: 1079px) {
  .btn-globe {
    width: 35px;
    height: 35px;
  }

  .btn-city {
    width: 35px;
    height: 35px;
  }
}