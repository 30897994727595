.category-section{
    width: 100%;
    height: 800px;
    overflow: hidden;
    transition: all ease 0.5s;
    transform: translate(0, 10%);
    opacity: 0;
    will-change: opacity, transform;
}

@media only screen and (max-width: 1079px) {
    .category-section{
        height: auto;
        padding-bottom: 15px;
    }
}

.category-section.active{
    transform: translate(0, 0);
    opacity: 1;
    transition-delay: 0.3s;
}

.category-section nav{
    padding: 10px;
    background-color: rgba(14, 27, 76, 0.9);
    border: 1px solid #bda261;
    border-radius: 15px;
    display: block;
    width: 100px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 4000;
}

@media only screen and (max-width: 1079px) {
    .category-section nav{
        display: none;
    }
}

.category-section nav span{
    font-family: 'VisaDialectHightSemibold';
    font-size: 12px;
    color: #aca47b;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
    display: block;
}

.category-section nav ul{
    list-style: none;
}

.category-section nav li{
    margin-top: 10px;
}

.category-section nav li img{
    margin-bottom: 5px;
}

.category-section nav a{
    width: 100%;
    border: 2px solid #aca47b;
    border-radius: 15px;
    background-color: transparent;
    display: block;
    text-decoration: none;
    padding: 5px 0;
}

.category-section nav a span{
    font-family: 'VisaDialectHightSemibold';
    font-size: 10px;
    color: #aca47b;
    text-align: center;
    text-transform: none;
    text-align: center;
    display: block;

}

.category-section nav li.active a{
    background-color: rgba(255, 255, 255, 0.2);
}

.category-section nav a img{
    display: block;
    width: 100%;
    height: auto;
}