.city-card {
    width: 450px;
    height: auto;
    background-color: rgba(14, 27, 76, 0.9);
    border: 1px solid #bda261;
    position: absolute;
    top: 146px;
    left: 570px;
    border-radius: 50px;
    padding: 50px;
    transition: all ease 0.4s;
    opacity: 0;
    transform: translate(0, 10%);
    will-change: opacity, transform;
    pointer-events: none;
}

.city-card.active {
    opacity: 1;
    transform: translate(0, 0);
    transition-delay: 0.3s;
    pointer-events: initial;
}

.city-card h1 {
    font-size: 49px;
    font-family: 'VisaDialectHightRegular';
    color: white;
    font-weight: normal;
    text-align: center;
}

.city-card h2 {
    font-size: 24px;
    color: #aca47b;
    font-family: 'VisaDialectHightRegular';
    font-weight: normal;
    text-align: center;
    margin-bottom: 10px;
}

.city-card p.date {
    font-family: 'VisaDialectHightSemibold';
    font-size: 14px;
    color: #aca47b;
    text-align: center;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.city-card p.date img {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 10px;
}

.city-card h3 {
    font-family: 'VisaDialectHightSemibold';
    font-size: 14px;
    color: #aca47b;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 8px;
}

.city-card nav {
    margin-bottom: 30px;
}

.city-card nav ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.city-card nav ul a {
    border: 2px solid #aca47b;
    border-radius: 15px;
    background-color: transparent;
    display: block;
    padding: 15px 0;
    text-decoration: none;
}

.city-card nav ul a img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 100px;
    margin: 0 auto 5px;
}

.city-card nav ul a span {
    font-family: 'VisaDialectHightSemibold';
    font-size: 12px;
    color: #aca47b;
    text-align: center;
    display: block;
    text-decoration: none;
}

.city-card .spotify {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.city-card .spotify a{
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.city-card .spotify span {
    font-family: 'VisaDialectHightSemibold';
    font-size: 10px;
    color: #aca47b;
}

.city-card .spotify span.text-1 {
    text-align: right;
}

.city-card .spotify span.text-2 {
    text-align: left;
}

.city-card .spotify img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 50px;
    margin: 10px;
}

.city-card .footer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.city-card .footer-item {
    padding: 0 10px;
}

.city-card .footer-item:nth-child(1) {
    border-right: 1px solid #aca47b;
    ;
}

.city-card h4 {
    font-family: 'VisaDialectHightSemibold';
    font-size: 12px;
    color: #aca47b;
    text-align: center;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.city-card p {
    font-family: 'VisaDialectHightSemibold';
    font-size: 12px;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.city-card p img.weather {
    margin-right: 10px;
}

.city-card p img.exchange {
    margin-right: 10px;
}

.city-card .footer .footer-item h4{
    font-size: 9px !important;
}

.city-card .footer .footer-item span{
    color: #aca47b;
    font-size: 16px;
    margin: 0 3px;
}

@media only screen and (max-width: 1079px) {
    .city-card {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px;
        border-radius: 25px;
    }

    .city-card h1 {
        font-size: 30px;
    }

    .city-card h2 {
        font-size: 18px;
    }

    .city-card nav ul li {
        margin: 0 5px;
        width: calc(33.33% - 10px);
    }

    .city-card .spotify a{
        pointer-events: initial;
    }
    
    .city-card .spotify span {
        font-size: 14px;
    }
    
    .city-card .spotify span.text-1 {
        max-width: 200px;
    }
    
    .city-card .spotify span.text-2 {
        display: none;
    }
    
    .city-card .spotify img.img-2 {
        display: none;
    }
}