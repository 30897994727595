.category
{
    width: 100%;
    height: 1460px;
    background: center repeat;
    pointer-events: none;
    opacity: 0;
    will-change: opacity;
    transition: opacity ease 0.4s;
}

@media only screen and (max-width: 1079px) {
    .category{
        height: auto;
    }
}

.category.active
{
    opacity: 1;
    pointer-events: initial;
}

.category-menu
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 30px;
}

@media only screen and (max-width: 1079px) {
    .category-menu{
        padding: 15px;
    }
}

.category h1
{
    font-size: 50px;
    line-height: 46px;
    font-family: 'VisaDialectHightRegularItalic';
    color: white;
    font-weight: normal;
    text-align: center;
    margin-left: 30px;
}

.category h1 span
{
    color: #aca47b; 
    font-size: 40px;
    border-left: 2px solid #354c7e;
    padding-left: 30px;
    margin-left: 30px;
}

@media only screen and (max-width: 1079px) {
    .category h1{
        font-size: 25px;
        line-height: 1.5;
        margin-left: 15px;
        margin-bottom: 0;
    }

    .category h1 span{
        font-size: 20px;
        padding-left: 10px;
        margin-left: 10px;        
    }
}