.globe {
    width: 100%;
    height: 1510px;
    pointer-events: none;
    opacity: 0;
    will-change: opacity;
    transition: opacity ease 0.4s;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 50px solid #bda261;
}

@media only screen and (max-width: 1079px) {
    .globe {
        height: calc(100vh - 168px);
    }
}

.globe.active {
    opacity: 1;
    pointer-events: initial;
}

.globeButton {
    font-size: 30px;
    padding: 20px;
    margin: 10px;
    border: none;
}

.marker {
    color: #b89f64;
    font-family: 'VisaDialectHightMediumItalic';
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.marker span {
    text-shadow: 2px 2px 2px rgba(0,0,0,0.9);
}

@media only screen and (max-width: 1079px) {
    .marker {
        font-size: 18px;
    }

    .marker img {
        display: block;
        width: 40px;
        height: auto;
    }
}

.globe-zoom {
    z-index: 999;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.globe-zoom button {
    margin: 10px;
    background-color: #b79f64;
    color: white;
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 30px;
    text-align: center;
}

@media only screen and (max-width: 1079px) {
    .globe-zoom {
        display: none;
    }
}