.category-section-card {
    width: 650px;
    height: 650px;
    border-radius: 50px;
    border: 1px solid #bda261;
    background-color: #1f2a3e;
    overflow: hidden;
}

.category-section-card .image {
    width: 100%;
    height: 100%;
    transition: all ease 0.4s;
    pointer-events: initial;
    will-change: opacity, width, height;
}

.category-section-card .image img.thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all ease 0.4s;
    opacity: 0.3;
    display: block;
    will-change: opacity;
}

.category-section-card .content-card {
    opacity: 0;
    padding: 40px 0;
    transition: all ease 0.4s;
    background-color: #1f2a3e;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.category-section-card .content-card .content-left {
    width: 25%;
    padding: 0 40px 40px;
}

.category-section-card .content-card .content-left img {
    display: block;
    width: 100%;
    height: auto;
}

.category-section-card .content-card .content-left span, .category-section-card .content-card .content-left a {
    color: white;
    font-family: 'VisaDialectHightSemibold';
    font-size: 12px;
    text-align: left;
    display: block;
    padding-left: 8px;
    text-decoration: none;
}

.category-section-card .content-card .content-left a{
    display: none;
}

.category-section-card .content-card .content-right {
    width: 75%;
    padding-right: 40px;
}

.category-section-card .content-card .content-right h5 {
    color: #b89f64;
    font-family: 'VisaDialectHightSemibold';
    font-size: 32px;
    margin-bottom: 15px;
}

.category-section-card .content-card .content-right p {
    color: #b89f64;
    font-family: 'VisaDialectHightRegular';
    font-size: 15px;
    line-height: 1.5;
}

.category-section-card.active {
    width: 770px;
    height: 770px;
    z-index: 3002;
    opacity: 1;
}

.category-section-card.active .image {
    width: 100%;
    height: 50%;
    pointer-events: none;
    opacity: 1;
}

.category-section-card.active .image img.thumb {
    opacity: 1;
}

.category-section-card.active .content-card {
    opacity: 1;
}

@media only screen and (max-width: 1079px) {
    .category-section-card, .category-section-card.active {
        width: calc(100vw - 30px);
        height: auto;
        border-radius: 15px;
        overflow: auto;
        z-index: 3002;
        opacity: 1;
        margin-bottom: 15px;
    }
    
    .category-section-card .image, .category-section-card.active .image {
        height: 50%;
        opacity: 1;
    }
    
    .category-section-card .image img.thumb, .category-section-card.active .image img.thumb {
        opacity: 1;
    }
    
    .category-section-card .content-card, .category-section-card.active .content-card {
        opacity: 1;
        padding: 15px;
        flex-wrap: wrap;
    }
    
    .category-section-card .content-card .content-left, .category-section-card.active .content-card .content-left {
        order: 2;
        width: 100%;
        padding: 15px;
    }

    .category-section-card .content-card .content-left img, .category-section-card.active .content-card .content-left img{
        display: none;
    } 

    .category-section-card .content-card .content-left span, .category-section-card.active .content-card .content-left span{
        display: none;
    }

    .category-section-card .content-card .content-left a, .category-section-card.active .content-card .content-left a{
        padding: 0;
        display: inline-block;
    }
    
    .category-section-card .content-card .content-right, .category-section-card.active .content-card .content-right {
        width: 100%;
        padding: 15px;
        order: 1;
    }
    
    .category-section-card .content-card .content-right h5 {
        font-size: 20px;
        margin-bottom: 15px;
    }
    
    .category-section-card .content-card .content-right p {
        font-size: 14px;
        line-height: 1.5;
    }    
}