.benefits-category-one img{
    
    width: 100%;
    height: auto;
}

.only-mobile{
    display: none;
}

.only-desktop{
    display: block;
}

@media only screen and (max-width: 1079px) {
    .only-mobile{
        display: block;
    }
    
    .only-desktop{
        display: none;
    }
}