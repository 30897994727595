.benefits .benefits-home {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.benefits .benefits-home .left {
    width: 50%;
    border-right: 1px solid #1f2a3e;
}

.benefits .benefits-home .left img {
    display: block;
    width: 100%;
    height: auto;
}

.benefits .benefits-home .right {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-left: 1px solid #1f2a3e;
}

.benefits .benefits-home .right button{
    display: block;
    width: 50%;
    border: none;
    background: transparent;
    cursor: pointer;
}

.benefits .benefits-home .right button:nth-child(1){
    border-right: 1px solid #1f2a3e;
    border-bottom: 1px solid #1f2a3e;
}

.benefits .benefits-home .right button:nth-child(2){
    border-left: 1px solid #1f2a3e;
    border-bottom: 1px solid #1f2a3e;
}

.benefits .benefits-home .right button:nth-child(3){
    border-right: 1px solid #1f2a3e;
    border-top: 1px solid #1f2a3e;
}

.benefits .benefits-home .right button:nth-child(4){
    border-left: 1px solid #1f2a3e;
    border-top: 1px solid #1f2a3e;
}

.benefits .benefits-home .right img {
    display: block;
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1079px) {
    .benefits .benefits-home {
        flex-wrap: wrap;    
    }
    
    .benefits .benefits-home .left {
        width: 100%;
        border:none;
    }
    
    .benefits .benefits-home .right {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        border-top: 1px solid #1f2a3e;
        border-left: none;
    }
}