.terms{
    width: 100%;
    height: 50px;
    background-color: #bda261;
    display: flex;
    justify-content: center;
    align-items: center;
}

.terms p{
    color: white;
    text-align: center;
    font-family: 'VisaDialectHightMediumItalic';
    font-size: 20px;
}

@media only screen and (max-width: 1079px) {
    .terms p{
        font-size: 14px;
        line-height: 1.3;
    }
}

.terms p button{
    color: #2537c6;
    background: transparent;
    border: none;
    text-align: center;
    font-family: 'VisaDialectHightMediumItalic';
    font-size: 20px;
    margin-left: 10px;
}

@media only screen and (max-width: 1079px) {
    .terms p button{
        font-size: 14px;
        line-height: 1.5;
    }
}

.terms.active{
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    padding: 15px;
}

@media only screen and (max-width: 1079px) {
    .terms.active{
        position: relative;
    }
}

.terms.active p:nth-child(1){
    color: white;
    text-align: center;
    font-family: 'VisaDialectHightMediumItalic';
    font-size: 20px;
    margin-bottom: 15px;
}

.terms.active p:nth-child(2){
    color: white;
    text-align: justify;
    font-family: 'VisaDialectHightMediumItalic';
    font-size: 18px;
    line-height: 1.5;
}

@media only screen and (max-width: 1079px) {
    .terms.active p:nth-child(1){
        font-size: 14px;
        line-height: 1.5;
    }
    
    .terms.active p:nth-child(2){
        font-size: 14px;
        line-height: 1.5;
    }
}