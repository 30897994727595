.city
{
    width: 100%;
    height: 1460px;
    background: center no-repeat;
    background-size: cover;
    pointer-events: none;
    opacity: 0;
    will-change: opacity;
    transition: opacity ease 0.4s;
    padding: 30px;
    background-color: white;
}

@media only screen and (max-width: 1079px) {
    .city{
        height: auto;
        background-attachment: fixed;
        padding: 15px;
        min-height: 100%;
    }
}

.city img.background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.city.active
{
    opacity: 1;
    pointer-events: initial;
}

.city-info{
    font-family: 'VisaDialectHightBoldItalic';
    color: white;
    font-size: 23px;
    line-height: 1.2;
    margin-top: 70px;
}

@media only screen and (max-width: 1079px) {
    .city-info{
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
        text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
        text-align: center;
        padding-bottom: 50px;
    }

    .city-info br{
        display: none;
    }
}