.benefits {
    background-color: #1f2a3e;
    opacity: 0;
    will-change: opacity;
    transition: opacity ease 0.4s;
}

.benefits.active {
    opacity: 1;
}

.benefits-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 25px 30px;
}

.benefits-title h1 {
    font-size: 50px;
    line-height: 46px;
    font-family: 'VisaDialectHightRegularItalic';
    color: white;
    font-weight: normal;
    text-align: center;
    margin-left: 30px;
}

.benefits-title h1 span {
    color: #aca47b;
    font-size: 40px;
    border-left: 2px solid #354c7e;
    padding-left: 30px;
    margin-left: 30px;
}



.benefits-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 43px;
}

.benefits-menu ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.benefits-menu ul li {
    border-right: 1px solid #2e5ba7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 140px;
    height: 100px;
}

.benefits-menu ul li img {
    opacity: 0.4;
    display: block;
    width: 100%;
    height: auto;
    max-width: 49px;
    margin: 0 auto;
}

.benefits-menu ul li span {
    font-family: 'VisaDialectHightMediumItalic';
    font-size: 14px;
    color: #aca47b;
    text-align: center;
    text-transform: none;
    text-align: center;
    display: block;
    opacity: 0.4;
}

.benefits-menu ul li.active img {
    opacity: 1;
}

.benefits-menu ul li.active span {
    opacity: 1;
}

.benefits-menu ul li:last-child {
    border: none;
}

.benefits-menu ul li button {
    background: transparent;
    border: none;
    padding: 5px 30px;

}

.benefits .benefits-background {
    display: block;
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1079px) {
    .benefits-title {
        padding: 15px;
    }

    .benefits-title h1 {
        font-size: 25px;
        line-height: 1.5;
        margin-left: 15px;
        margin-bottom: 0;
    }

    .benefits-title h1 span {
        font-size: 20px;
        padding-left: 10px;
        margin-left: 10px;
    }
    
    .benefits-menu {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 15px;
    }

    .benefits-menu .benefits-qrcode{
        display: none;
    }

    .benefits-menu ul{
        align-items: stretch;
        width: 100%;
    }
    
    .benefits-menu ul li {
        width: calc(20% - 1px);
        height: auto;
    }
    
    .benefits-menu ul li img {
        max-width: 40px;
    }
    
    .benefits-menu ul li span {
        font-size: 10px;
    }
    
    .benefits-menu ul li:last-child {
        border: none;
    }
    
    .benefits-menu ul li button {
        padding: 10px;
    
    }

}