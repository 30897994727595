.category-section-carousel
{
    width: 100%;
    height: 800px;
    transition: all ease 0.3s;
    will-change: transform, opacity;
    opacity: 0;
    overflow: hidden;
    z-index: 999;
}

@media only screen and (max-width: 1079px) {
    .category-section-carousel{
        height: auto;
        overflow: auto;
    }
}

.category-section-carousel.active
{
    opacity: 1;
    transform: translate(0, 0);
}

.category-section-carousel .category-section-carousel-wrapper
{
    height: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    will-change: transform;
    transition: all ease 0.3s;
}

@media only screen and (max-width: 1079px) {
    .category-section-carousel .category-section-carousel-wrapper{
        height: auto;
        display: block;
        padding: 15px;
    }
}

.category-section-carousel button{
    background: none;
    border: none;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 40px;
    z-index: 4000;
}

.category-section-carousel button img{
    display: block;
    width: 100%;
    height: auto;
}

.category-section-carousel button.icon-prev {
    left: 25px;
}

.category-section-carousel button.icon-next {
    right: 25px;
}

@media only screen and (max-width: 1079px) {
    .category-section-carousel button{
        display: none;
    }
}