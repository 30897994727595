.initial {
    width: 100%;
    /* min-height: calc(100vh - 168px); */
    height: 1510px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    z-index: 4;
}

.initial.active {
    display: flex;
}

.initial .initial-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.initial img.initial-qrcode {
    display: block;
    width: 100%;
    height: auto;
    max-width: 294px;
}

.initial img.initial-mobile-qrcode {
    display: none;
    width: 100%;
    height: auto;
    max-width: 294px;
}

@media only screen and (max-width: 1079px) {
    .initial {
        background-color: rgba(0, 0, 0, 0.5);
        height: calc(100vh - 168px);
    }

    .initial img.initial-qrcode {
        display: none;
    }

    .initial img.initial-mobile-qrcode {
        width: 80%;
        display: block;
    }
}