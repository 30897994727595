.initial-control{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 150px;
    left: 0;
    width: 100%;
    z-index: 5;
}

@media only screen and (max-width: 1079px) {
    .initial-control{
        display: none;
    }
}

.initial-control-button{
    font-family: 'VisaDialectHightSemibold';
    font-size: 14px;
    color: white;
    text-align: center;
    background-color: #b89f64;
    border-radius: 50px;
    padding: 23px 80px;
    text-transform: uppercase;
    text-decoration: none;
    margin-left: 15px;
}


.switch-language
{
    background-color: #15234b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 65px;
    max-width: 280px;
    border-radius: 50px;
    border: 1px solid #b89f64;
    padding: 5px 15px;
}

.switch-language nav
{
    
}

.switch-language ul 
{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
}

.switch-language li
{
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-family: 'VisaDialectHightSemibold';
    font-size: 14px;
    color: #b89f64;
    text-transform: uppercase;
    text-align: center;
    border-radius: 50%;
    margin: 2px;
    opacity: 0.7;
    border: 1px solid #15234b;
}

.switch-language li.active
{
    border: 1px solid #b89f64;
    color: white;
    opacity: 1;
}

.switch-language li a
{
    color: inherit;
    text-decoration: none;
}