.benefits .benefits-category {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.benefits .benefits-category .left {
    width: 50%;
}

.benefits .benefits-category .left img {
    display: block;
    width: 100%;
    height: auto;
}

.benefits .benefits-category .right {
    display: block;
    width: 50%;
    overflow: hidden;
    padding: 20px 0;
}

.benefits .benefits-category .right::after {
    top: 0;
    left: 0;
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 100px 0px 70px -100px rgba(0, 0, 0, 1), inset -100px 0px 70px -100px rgba(0, 0, 0, 1);
    pointer-events: none;
}

.benefits-gallery-carousel {
    width: 100%;
    overflow: hidden;
    padding: 15px 0;
}

.benefits-gallery-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all ease 0.3s;
    will-change: transform;
}

.benefits-gallery-wrapper .item {
    display: block;
    width: 430px;
    height: auto;
    margin: 0 10px;
    border: 1px solid #b89f64;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
}

.benefits-gallery-wrapper .item img {
    display: block;
    width: 100%;
    height: auto;
}

.button-nav {
    background: transparent;
    border: none;
    width: 30px;
    height: 50px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.button-nav img {
    display: block;
    width: 100%;
    height: auto;
}

.button-prev {
    left: 10px;
}

.button-next {
    right: 10px;
}





@media only screen and (max-width: 1079px) {
    .benefits .benefits-category {
        flex-wrap: wrap;
    }

    .benefits .benefits-category .left {
        width: 100%;
    }

    .benefits .benefits-category .right {
        width: 100%;
        overflow: auto;
        padding: 0;
    }

    .benefits-gallery-carousel {
        overflow: auto;
        padding: 0;
    }

    .benefits-gallery-wrapper {
        display: block;
        width: 100%;
        padding: 15px;
    }

    .benefits-gallery-wrapper .item {
        width: 100%;
        margin: 10px 0;
        border-radius: 15px;
    }

    .button-nav {
        display: none;
    }
}