header {
    background-color: #0f1b4d;
    z-index: 1000;
}

header .header-top {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

header .header-top img {
    width: 100%;
    height: auto;
    display: block;
}

header .header-bottom-initial {
    display: flex;
    width: 100%;
    height: 70px;
    justify-content: center;
    align-items: center;
}

header .header-bottom-initial span {
    color: white;
    font-family: 'VisaDialectHightMediumItalic';
    font-size: 26px;
    margin-left: 15px;
}



header .header-bottom {
    display: flex;
    width: 100%;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    background-color: #0a143c;
}

header .header-bottom nav.fixed{
    display: none;
}

.menu-button-wrapper {
    margin-left: 15px;
}


header .header-bottom .menu-button,
header .header-bottom .menu-button::before,
header .header-bottom .menu-button::after {
    display: block;
    background-color: #b89f64;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
    transform: translate(0, 12px);
}

header .header-bottom .menu-button::before {
    content: '';
    transform: translate(0, -8px);
}

header .header-bottom .menu-button::after {
    content: '';
    transform: translate(0, 8px);
}

.menu-button-wrapper {
    width: 30px;
    height: 30px;
}

.menu-button-wrapper.active .menu-button::before {
    margin-top: 0px;
    transform: translate(0, 0px) rotate(405deg);
}

.menu-button-wrapper.active .menu-button {
    background: rgba(255, 255, 255, 0);
}

.menu-button-wrapper.active .menu-button::after {
    transform: translate(0, 0px) rotate(-405deg);
}

.menu-hover{
    background-color: #1f2a3e;
    padding: 20px;
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    transition: all ease 0.3s;
    transform: translate(-50%, 0);
    will-change: transform, opacity;
    pointer-events: none;
}

.menu-hover.active{
    pointer-events: initial;
    opacity: 1;
    transform: translate(0, 0);
}

.menu-hover ul{
    list-style: none;
}

.menu-hover li{
    font-family: 'VisaDialectHightMedium';
    color: white;
    font-size: 14px;
    padding: 10px 0;
    border-bottom: 1px solid #59564c;
}

.menu-hover li:last-child{
    border: none;
}

.menu-hover li.list-lang{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-hover li.list-lang a{
    padding: 15px 10px;
    opacity: 0.5;
    background-color: #0e1b4c;
    text-transform: uppercase;
    color: #b09962;
    margin: 0 5px;
    font-size: 12px;
}

.menu-hover li.list-lang a.active{
    opacity: 1;
}

.menu-hover li a{
    text-decoration: none;
    color: white;
    font-family: 'VisaDialectHightMedium';
    font-size: 18px;
}

@media only screen and (max-width: 1079px) {

    header .header-top img {
        width: auto;
        height: 118px;
        display: block;
    }

    header .header-bottom-initial {
        height: 50px;
    }

    header .header-bottom-initial img {
        display: block;
        width: auto;
        height: 50px;
    }

    header .header-bottom-initial span {
        font-size: 16px;
        margin-left: 8px;
    }

    header .header-bottom {
        height: 50px;
        display: flex;
        /* justify-content: flex-start; */
        align-items: center;
    }

    header .header-bottom img {
        display: none;
    }
    
    .menu-button-wrapper {
        margin-left: 10px;
    }
    
    .menu-hover li a{
        font-size: 16px;
    }

    header .header-bottom nav.fixed{
        display: block;
    }
    
    header .header-bottom nav.fixed ul{
        list-style: none;
    }
    
    header .header-bottom nav.fixed li{
        font-family: 'VisaDialectHightMedium';
        color: white;
        font-size: 14px;
        padding: 10px 0;
        border-bottom: 1px solid #59564c;
    }
    
    header .header-bottom nav.fixed li:last-child{
        border: none;
    }
    
    header .header-bottom nav.fixed li.list-lang{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    header .header-bottom nav.fixed li.list-lang a{
        padding: 15px 10px;
        opacity: 0.5;
        background-color: #0e1b4c;
        text-transform: uppercase;
        color: #b09962;
        margin: 0 5px;
        font-size: 12px;
    }
    
    header .header-bottom nav.fixed li.list-lang a.active{
        opacity: 1;
    }
    
    header .header-bottom nav.fixed li a{
        text-decoration: none;
        color: white;
        font-family: 'VisaDialectHightMedium';
        font-size: 18px;
    }
}