.city-category-gallery
{
    width: 100%;
    height: 550px;
    overflow: hidden;
}

@media only screen and (max-width: 1079px) {
    .city-category-gallery{
        padding-top: 50.92%;
        height: auto;
    }
}

.city-category-gallery img
{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity ease 0.5s;
    z-index: 3000;
    object-fit: cover;
}

.city-category-gallery img.active
{
    opacity: 1;
    z-index: 3001;
}

.city-category-gallery img.toUp
{    
    z-index: 3002;
}