.content {
    width: 100%;
    height: 1510px;
    overflow: hidden;
}

@media only screen and (max-width: 1079px) {
    .content {
        height: calc(100vh - 168px);
        overflow: auto;
    }
}